.dimmer {
    position: fixed;
	/* WHAT A SCHTUPID ACTION */
    width: 200%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
}

.dialog-container {
	position: absolute;
	top: 0px;
	left: -350px;
	width: 100%;
	height: 100%;
	z-index: 10000;
}

.dialog {
    position: fixed;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2b2e46;
    border-radius: 24px;
    overflow: hidden;
    min-width: 350px;
    border: 0.1em solid #272831;
    animation: dialog_in 0.2s cubic-bezier(0.075, 0.82, 0.165, 1.1);
    color: white;
    font-family: BarlowSemiCondensed-Regular;
}

.dialog-header {
    font-size: 24px;
}

.dialog-element {
    padding: 8px;
}

.dialog-header, .dialog-btns-container {
    background-color: #202232;
}

.dialog-btns-container {
    display: flex;
    justify-content: right;
    border-top: 2px solid #3f4151;
    gap: 8px;
}

.dialog-header {
    border-bottom: 2px solid #3f4151;
}

.dialog-description {
    font-size: 18px;
    padding: 14px 10px;
    white-space: pre-wrap;
}

.dialog-btns-container button {
    border-radius: 24px;
    padding: 5px 10px;
}

.dialog-btns-container button:nth-child(2):hover {
    background-color: rgb(105, 36, 36);
}

.dialog-btns-container button:nth-child(2) {
    background-color: rgb(147, 45, 45);
}

.dialog-btns-container button:nth-child(1):hover {
    background-color: rgb(38, 42, 62);
}

@keyframes dialog_in {
    0% {
        transform: translate(-50%, calc(-50% + 100px)) scaleX(0%);
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    100% {
        transform: translate(-50%, calc(-50%));
        opacity: 1;
    }
}
