h1 {
    font-family: "BarlowCondensed-Bold";
    margin: 0px 0px;
}

.portal-count {
    margin: 20px 0px;
    font-family: "BarlowSemiCondensed-SemiBold";
    font-size: 96px;
}

h3 {
    font-family: "BarlowSemiCondensed-Regular";
    margin: 0px 10px;
    font-size: 42px;
}

.game-header {
    text-align: center;
    border-radius: 24px;
    overflow: hidden;
    background-size: cover;
    background-position: 25%;
    margin-top: 12px;
}

.blur {
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.blur.map {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.blur.map span {
    width: fit-content;
}

.blur.map span:nth-child(1) {
    font-family: "BarlowSemiCondensed-SemiBold";
    font-size: 60px;
    margin-right: 6px;
}

.game-header-portal-count {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-header-categories {
    display: flex;
    height: 50px;
    background-color: #202232;
    gap: 2px;
}

.game-cat-button {
    background-color: #2b2e46;
    border: 0;
    color: #cdcfdf;
    font-family: "BarlowSemiCondensed-Regular";
    font-size: 22px;
    cursor: pointer;
    transition: all 0.1s;
    width: 100%;
}

.game-cat-button:hover, .game-cat-button.selected {
    background-color: #202232;
}

/* maplist */
.maplist {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 20px 0px;
}

.maplist-entry {
    background-color: #202232;
    border-radius: 24px;
    overflow: hidden;
}

.maplist-entry span {
    text-align: center;
    font-size: 20px;
    width: 100%;
    display: block;
    margin: 5px 0px;
    color: #cdcfdf;
}

.map-entry-image {
    display: flex;
    height: 200px;
    background-size: cover;
}

.difficulty-bar {
    display: flex;
    margin: 15px 10px;
}

.difficulty-bar span {
    text-align: left;
    margin-left: 0px;
    width: fit-content;
}

.difficulty-bar div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 2000px;
    margin-left: 2px;
    transform: translateY(1px);
}

.difficulty-bar div div {
    display: flex;
    height: 3px;
    width: 100%;
    background-color: #2B2E46;
}

.difficulty-bar div.one .difficulty-point:nth-child(1) {
    background-color: #51C355;
}

.difficulty-bar div.two .difficulty-point:nth-child(-n+2) {
    background-color: #8AC93A;
}

.difficulty-bar div.three .difficulty-point:nth-child(-n+3) {
    background-color: #8AC93A;
}

.difficulty-bar div.four .difficulty-point:nth-child(-n+4) {
    background-color: #C35F51;
}

.difficulty-bar div.five .difficulty-point:nth-child(-n+5) {
    background-color: #dd422e;
}

.dropdown {
    cursor: pointer;
    user-select: none;
    display: flex;
    width: fit-content;
    align-items: center;
}

.dropdown i {
    transform: translate(5px, 8px) rotate(-90deg);
}

.dropdown-elements {
    position: absolute;
    z-index: 1000;
    background-color: #2B2E46;
    border-radius: 15px;
    overflow: hidden;
    padding: 4px 4px;
    animation: dropdown-in 0.1s ease;
}

.dropdown-element {
    cursor: pointer;
    font-size: 20px;
    border-radius: 2000px;
    padding: 1px 4px;
}

.dropdown-element:hover {
    background-color: #202232;
}

@keyframes dropdown-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
