main {
    overflow: auto;
    overflow-x: hidden;
    position: relative;

    width: calc(100% - 380px); 
    height: 100vh;
    left: 350px;

    padding-right: 30px;

    font-size: 40px;
    font-family: BarlowSemiCondensed-Regular;
    color: #cdcfdf;

}

a {
    color: inherit;
    width: fit-content;
}

body {
    overflow: hidden;
    background-color: #141520;
    margin: 0;
}

.loader {
    animation: loader 1.2s ease infinite;
    background-size: 400% 300%;
    background-image: linear-gradient(-90deg, #202232 0%, #202232 25%, #2a2c41 50%, #202232 75%, #202232 100%);
    user-select: none;
}

.loader-text {
    animation: loader 1.2s ease infinite;
    background-size: 400% 300%;
    background-image: linear-gradient(-90deg, #202232 0%, #202232 25%, #2a2c41 50%, #202232 75%, #202232 100%);
    user-select: none;
    color: #00000000;
    border-radius: 1000px;
}

@keyframes loader {
    0% {
        background-position: 100% 20%;
    }

    50% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'BarlowCondensed-Bold';
    src: local('BarlowCondensed-Bold'), url(./fonts/BarlowCondensed-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'BarlowCondensed-Regular';
    src: local('BarlowCondensed-Regular'), url(./fonts/BarlowCondensed-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'BarlowSemiCondensed-Regular';
    src: local('BarlowSemiCondensed-Regular'), url(./fonts/BarlowSemiCondensed-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'BarlowSemiCondensed-SemiBold';
    src: local('BarlowSemiCondensed-Regular'), url(./fonts/BarlowSemiCondensed-SemiBold.ttf) format('truetype');
}
