div#upload-run{
    position: absolute;
    left: 50%;
    z-index: 20;
    width: 320px; height: auto;
    /* background-color: red; */

    transform: translateY(-68%);
}

#upload-run-menu{
    position: absolute;
    left: calc(50%); top: 130px;
    transform: translateX(-50%);
    background-color: #2b2e46;
    z-index: 3; color: white;
    font-family: BarlowSemicondensed-SemiBold;
}

#upload-run-menu-add,
#upload-run-menu-edit{
    box-shadow: 0 0 40px 16px black;
    outline: 8px solid #2b2e46;
    border-radius: 20px;
    font-size: 40px;
	text-wrap: nowrap;
}

#upload-run-menu-add>div,
#upload-run-menu-edit>div{
    display: flex;
    font-size: 20px;
    padding: 0px 15px;
	gap: 2px;
}

#upload-run-menu-add>div .upload-run-item {
	margin: 0px 20px;
}

#upload-run-block{
    position: absolute;
    background-color: black;
    opacity: .3;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: no-drop;
}

.upload-run-dropdown-container .dropdown-cur {
    user-select: none;
    cursor: pointer;
	width: 240px;
}

.upload-run-dropdown {
    position: absolute;
    user-select: none;
    cursor: pointer;
    z-index: 1000;
    background-color: #222538;
    border-radius: 10px;
    overflow: hidden;
    animation: dropdown-in 0.2s ease;
    max-height: 300px;
    overflow-y: scroll;
    width: 260px;
}

.upload-run-dropdown div {
    padding: 8px;
}

.upload-run-dropdown div:hover {
    background-color: #414669;
}

.upload-run-dropdown.hidden {
    display: none;
}

.upload-run-demo-name {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 380px;
}

@keyframes dropdown-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

button, input {
    background-color: #2b2e46;
    border: none;
    font-family: BarlowSemiCondensed-Regular;
    color: #CDCFDF;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 0px;
    transition: all 0.2s ease;
}

.upload-run-buttons-container button {
    border-radius: 32px;
}

button:hover {
    background-color: #222538;
}

.upload-run-map-container {
    display: flex;
}

.upload-run-buttons-container {
    display: flex;
    margin-top: 10px;
}
.upload-run-buttons-container button {
    width: 100%;
}

#host_demo, #partner_demo {
	background-color: rgba(0, 0, 0, 0);
	display: none;
}

.upload-run-drag-area {
	border: 2px dashed grey;
	border-radius: 10px;
	height: 150px;
	cursor: pointer;
	width: 360px;
	transition: all 0.2s ease;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px 0px;
}

.upload-run-drag-area-highlight, .upload-run-drag-area-highlight-partner {
	border: 2px dashed white;
}

.upload-run-drag-area-hidden {
	height: fit-content;
	text-align: left;
	border: none;
	align-items: flex-start;
}

