.nav-container {
    justify-content: center;
    display: flex;
}

.nav-container div {
    display: flex;
    width: 100%;
    background-color: #202232;
    margin-top: 20px;
    border-radius: 2000px;
    overflow: hidden;
    gap: 3px;
}

.nav-container button {
    background-color: #2B2E46;
    color: inherit;
    border: none;
    font-family: inherit;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 26px;
    padding: 10px 0px;
    transition: all 0.1s;
}

.nav-container button:hover, .nav-container button.selected {
    background-color: #202232;
}

.nav-1 div {
    width: 60%;
}

.nav-2 div {
    width: 75%;
}

.rankings-leaderboard {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    margin-top: 20px;
}

.ranks-container {
    display: flex;
    width: calc(60% - 20px);
    padding: 8px 8px;
    background-color: #202232;
    border-radius: 32px;
    flex-direction: column;
    gap: 7px;
}

.leaderboard-entry {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    text-align: center;
    align-items: center;
    width: 100%;
    background-color: #2B2E46;
    border-radius: 2000px;
    padding: 6px 0px;
}

.leaderboard-entry div:nth-child(2) {
    text-align: left;
}

.leaderboard-entry div a {
    display: flex;
    align-items: center;
}

.leaderboard-entry div a span {
    margin-left: 5px;
}

.leaderboard-entry img {
    height: 34px;
    border-radius: 2000px;
}

.leaderboard-entry.header {
    background-color: rgba(0, 0, 0, 0);
    font-family: "BarlowSemiCondensed-SemiBold";
    padding: 2px 0px;
}

.leaderboard-entry.header span:nth-child(2) {
    text-align: left;
}

.ranks-container .splitter {
    width: calc(100% - 20px);
    display: flex;
    height: 0.13em;
    background-color: #b7b9c6;
    border-radius: 200px;
    transform: translateX(10px);
}
