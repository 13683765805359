#sidebar {
    overflow: hidden;
    position: absolute;
    background-color: #2b2e46;
    width: 320px;    height: 100vh;
    min-height: 670px;
    
}

 /* logo */
#logo{
    display: grid;
    grid-template-columns: 60px 220px;


    height:  80px;
    padding: 20px 0 20px 20px;
    cursor: pointer;
    user-select: none;
} 

#logo-text{
    font-family: BarlowCondensed-Regular;
    font-size: 36px;
    color: #FFF;
    line-height: 38px;
}
#logo-text>span>b{
    font-family: BarlowCondensed-Bold;
    font-size: 56px;
}

 /* Sidelist */
#sidebar-list{
    z-index: 2;
    background-color: #2b2e46;
    position: relative;
    height: calc(100vh - 120px);
    width: 320px; 
    /* min-height: 670px; */
    transition: width .3s;
} 
#sidebar-toplist>button:nth-child(1){margin-top: 5px;}
#sidebar-toplist{
    display: grid;
    
    margin: 0 5px 0 5px;
    justify-items: left;
    height: 400px;
    grid-template-rows: 45px 50px 50px 50px 50px 50px 50px 50px auto;
}

#sidebar-bottomlist{
    display: grid;

    margin: 0 5px 0 5px;
    justify-items: left;
    grid-template-rows: calc(100vh - 720px) 50px 50px 50px;
}
.sidebar-button>span{
    font-family: BarlowSemiCondensed-Regular;
    font-size: 18px;
    color: #CDCFDF;
    height: 32px;
    line-height: 28px;
    transition: opacity .1s;
}
.sidebar-button{
    display: grid;
    grid-template-columns: 50px auto;
    place-items: left;
    text-align: left;
    
    background-color: inherit;
    cursor: pointer;
    border: none;
    width: 310px;
    height: 40px;
    border-radius: 20px;
    padding: 0.3em 0 0 11px;

    transition: 
    width .3s,
    background-color .15s,
    padding .3s;
}

.sidebar-button .avatar-img {
	border-radius: 50px;
}

.logout-button{
    display: grid;
    grid-template-columns: 50px auto;
    place-items: left;
    text-align: left;
    
    background-color: inherit;
    cursor: pointer;
    border: none;
    width: 310px;
    height: 40px;
    border-radius: 20px;
    padding: 0.4em 0 0 11px;

    transition: 
    width .3s,
    background-color .15s,
    padding .3s;
}

.submit-run-button {
    display: grid;
    grid-template-columns: 50px auto;
    place-items: left;
    text-align: left;
    
    background-color: inherit;
    cursor: pointer;
    border: none;
    width: 310px;
    height: 40px;
    border-radius: 20px;
    padding: 0.4em 0 0 11px;

    transition: 
    width .3s,
    background-color .15s,
    padding .3s;
    background-color: #202232aa;
}

.submit-run-button:hover {
    background-color: #202232;
}


.submit-run-button>span{
    font-family: BarlowSemiCondensed-Regular;
    font-size: 18px;
    color: #CDCFDF;
    height: 32px;
    line-height: 28px;
    transition: opacity .1s;
}

.sidebar-button-selected {
    background-color: #202232;
}

.sidebar-button-deselected {
    background-color: #20223200;
}

.sidebar-button-deselected:hover {
    background-color: #202232aa;
}

button>img {
    scale: 1.1;
    width: 20px;
    padding: 5px;
}

 /* Maplist */
#sidebar>div:nth-child(3){
    position: relative;
    background-color: #202232;
    color: #424562;
    z-index: 1;
    
    left: 52px; 
    top: calc(-100vh + 120px); 
    width: 268px; height: calc(100vh - 120px);
    min-height: 550px;
} 
input#searchbar[type=text]{
    margin: 10px 0 0 6px;
    padding: 1px 0px 1px 16px;
    width: 240px;
    height: 30px;

    font-family: BarlowSemiCondensed-Regular;
    font-size: 20px;
    
    background-color: #161723;
    color:#CDCFDF;
    
    border: 0;
    border-radius: 20px;

}
input[type=text]::placeholder{color:#2b2e46}
input[type=text]:focus{outline: inherit;}
a{text-decoration: none;height: 40px;}


#search-data{
    margin: 8px 0 8px 0;
    overflow-y: auto;
    max-height: calc(100vh - 172px);
    scrollbar-width: thin;
}
#search-data::-webkit-scrollbar{display: none;}
.search-map{
    margin: 10px 6px 0 6px;
    height: 80px; 

    border-radius: 20px;
    text-align: center;
    
    display: grid;

    border: 0;
    transition: background-color .1s;
    background-color: #2b2e46;
    grid-template-rows: 20% 20% 60%;
    width: calc(100% - 15px);
}
.search-map>span{
    color: #888;
    font-size: 16px;
    font-family: BarlowSemiCondensed-Regular;
}
.search-map>span:nth-child(3){
    font-size: 30px;
    color: #CDCFDF;
}

.search-player{
    overflow: hidden;
    margin: 10px 6px 0 6px;
    height: 80px; 

    border-radius: 20px;
    text-align: center;
    color: #CDCFDF;
    font-family: BarlowSemiCondensed-Regular;
    
    display: grid;
    place-items: center;
    grid-template-columns: 20% 80%;
    padding: 0 16px 0 16px;

    border: 0;
    transition: background-color .1s;
    background-color: #2b2e46;
}
.search-player>img{
    height: 60px; 
    border-radius: 20px;
}
.search-player>span{
    width:154px;
    font-size: 26px;
}











