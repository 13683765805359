.games-page {
    position: absolute;
    left: 320px;
    color: white;
    width: calc(100% - 320px);
    height: 100%;
    font-family: BarlowSemiCondensed-Regular;
    color: #ffffff;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.games-page-item-content {
    position: absolute;
    left: 50px;
    width: calc(100% - 100px);
}

.games-page-item-content a {
    color: inherit;
}

.games-page-header {
    margin-top: 50px;
    margin-left: 50px;
}

span>b {
    font-size: 56px;
    font-family: BarlowCondensed-Bold;
}

.loader-game {
    width: 100%;
    height: 256px;
    border-radius: 24px;
    overflow: hidden;
    margin: 25px 0px;
}

.games-page-item {
    width: 100%;
    height: 256px;
    background: #202232;
    border-radius: 24px;
    overflow: hidden;
    margin: 25px 0px;
}

.games-page-item-header {
    width: 100%;
    height: 50%;
    background-size: cover;
    overflow: hidden;
}

.games-page-item-header-img {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    filter: blur(4px);
    background-size: cover;
}

.games-page-item-header span>b {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform: translateY(-100%);
}

.games-page-item-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

.games-page-item-body-item {
    background: #2B2E46;
    text-align: center;
    width: max-content;
    width: calc(100% - 24px);
    height: 100px;
    border-radius: 24px;
    color: #CDCFDF;
    margin: 12px;
}

.games-page-item-body-item-title {
    margin-top: 0px;
    font-size: 26px;
}

.games-page-item-body-item-num {
    font-size: 50px;
    font-family: BarlowCondensed-Bold;
}