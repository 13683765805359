#background-image{
    z-index: -1;
    position: absolute;
    opacity: 10%;
    height: 50%;
    width: 100%
}
#background-image>img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
#background-image::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #161723, #0000);
}

/* Section 1: map name*/

#section1{
    margin: 20px 0 0 0;
    cursor: default;
}

.nav-button{
    height: 40px;
    background-color: #2b2e46;

    color: #cdcfdf;
    font-size: 18px;
    font-family: inherit;
    border: none;
    transition: background-color .1s;
}
/* #section1>div>.nav-button:nth-child(1){border-radius: 0px;}:nth-child(1){border-radius: 20px 0 0 20px;}
#section1>div>.nav-button:nth-child(2){border-radius: 0 20px 20px 0;margin-left: 2px;} */
.nav-button>span{padding: 0 8px 0 8px;}
.nav-button:hover{background-color: #202232;cursor: pointer;}

/* Section 2: navbar */
#section2{
    margin: 40px 0 0 0;

    display: grid; gap: 2px;
    grid-template-columns: 1fr 1fr 1fr;
}

#section2>.nav-button{
    height: 50px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    place-items: center;
}
#section2>.nav-button>img{scale: 1.2;}
#section2>.nav-button:nth-child(1){border-radius: 30px 0 0 30px;}
#section2>.nav-button:nth-child(3){border-radius: 0 30px 30px 0;}


/* Section 3: category + history */

#section3{
    margin: 40px 0 0 0;

    display: grid; 
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

#category{
    display: grid;
    height: 350px;
    border-radius: 24px;
    overflow: hidden;
    
}
#category>p{
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    cursor: default;
    color: white;
}

p>span.portal-count{font-weight: bold;font-size: 100px;vertical-align: -15%;}

#category-image{
    transform: translate(-20%, -15%);
    z-index: -1;
    overflow: hidden;
    width: 125%;
    margin: 22px;
    filter: blur(4px) contrast(80%) brightness(80%);
}

#category>span{
    margin-top: 70px;
    background-color: #202232;

    display: grid;
    gap: 2px;
}
#category>span>button{
    font-family: inherit;
    font-size: 18px;
    border: none;
    height: 40px;
    color: inherit;
    
    cursor: pointer;
    transition: background-color .1s;
}



#history>div>hr{border: 1px solid #2b2e46;margin: 8px 20px 0px 20px;}
#history{
    min-width: 560px;
    background-color: #202232;
    border-radius: 24px;

}

#records{overflow-y: auto; height: 256px;}
#records::-webkit-scrollbar{display: none;}

.record-top, .record{
    font-size: 18px;
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr;
}
.record-top{font-weight: bold;margin: 20px 20px 0 20px;cursor: default;}
.record{
    margin: 10px 20px 10px 20px;
    height: 44px; width: calc(100% - 40px);
        
    color: inherit;
    border-radius: 40px;
    place-items: center;
    
    border: 0;
    cursor: pointer;
    transition: background-color .1s;
}
#history>span{
    border-top: #202232 solid 2px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
#history>span>button{
    width: 100%; height: 40px;
    font-family: inherit;
    font-size: 18px;
    border: none;
    color: inherit;
    
    cursor: pointer;
    transition: background-color .1s;
}
#history>span>button:nth-child(1){border-radius: 0 0 0 24px;}
#history>span>button:nth-child(2){border-radius: 0 0 24px 0;}

#graph{
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows: 1fr 20px;
    height: 293px;

    margin: 10px 10px 5px 10px;
    overflow: hidden;
}
#graph>div:nth-child(1){ /* numbers */
    width: 20px; 
    display: grid;
    place-items: center;
    /* background-color: blue; */
}
#graph>div:nth-child(1)>span{
    font-size: 12px;
    line-height: 0;
}

#graph>div:nth-child(2){ /* big graph */
    position: relative;
    display: grid;
}
#graph>div:nth-child(2)>tr{
    display: flex;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
}
#graph>div:nth-child(2)>tr>td.graph_hor{
    width: 100%; 
    height: 0;
    padding: 0; 
    
    outline: 1px solid red;
}
#graph>div:nth-child(2)>tr>td.graph_ver{
    width: 0; 
    height: 100%;
    padding: 0;
    
    outline: 1px solid blue;
    transform: translateY(50%);
    z-index: 0;
    overflow: hidden;
}

#graph>div:nth-child(3){ /* dates */
    padding-right: 20px;
    z-index: 1;
    height: 16px;
    background-color: #202232;
    grid-column: 1 /3;
    font-size: 12px;
    display: grid;
    padding-top: 8px;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
}

.graph-button{
    position: absolute;
    padding: 0;
    border: 5px solid white;
    border-radius: 20px;
    cursor: pointer;
    transform: translateX(-50%);
}

#history>div>h5{text-align: center;height: 197px;}


/* Section 4: Difficulty + count */

#section4{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 40px 0 0 0;
}

#difficulty,
#count  {
    background-color: #202232;
    min-width: 250px;    
    text-align: center;
    cursor: default;

    border-radius: 24px;
    display: grid;
    grid-template-rows: 20px 40px 40px;
}
#difficulty>span:nth-child(1),
#count>span:nth-child(1){
    padding-top:10px;
    font-size: 18px;
    color:#cdcfdf
}
#difficulty>span:nth-child(2){
    font-size: 40px;
}
#difficulty>div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 calc(50% - 125px) 0 calc(50% - 125px);
    place-items: center;
}

.difficulty-rating{
    border-radius: 24px;
    width: 40px; height: 3px;
    background-color: #2b2e46;
}

#count>div{
    padding-top:10px;
    font-size: 50px;
    color:white
}

/* Section 5: route desc + video */
#section5{
    margin: 40px 0 20px 0;
    width: 100%;
}

#description{
    width: 100%;    height: auto;
    min-height: 342px;
}




#description>iframe{
    margin: 20px;
    float:right; 
    border: 0;
    border-radius: 24px;
    width: 608px;    height: 342px;
}

#description>h3{margin: 0 0 10px 0; color: white;}
#description-text{
    display: block;
    font-size: 21px;
    word-wrap: break-word;
    text-align: justify;
}
#description-text>b{font-size: inherit;}
#description-text>a{font-size: inherit;color: #3c91e6;}


/* Section 6: leaderboards */
#section6{
    margin: 40px 0 20px 0;
    min-height: 600px;
    background-color: #202232;

    border-radius: 24px;
    padding: 10px 10px 0 10px;

}


#section6>hr{border: 1px solid #2b2e46;margin: 8px 20px 0px 20px;}
#leaderboard-top{
    display: grid;
    font-size: 20px;
    height: 34px;
    padding-left: 60px;
    margin: 0 20px 0 20px;
}
#leaderboard-top>span{
    
    display: flex;
    place-items: flex-end;
}

#runner{
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: end;
}

#page-number{
    display: flex;
    width: auto;
    flex-direction: row-reverse;
}
#page-number>div{
width: 100px;   
place-items: center;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
text-align: center;
}
#page-number>div>button{
    width: 30px;
    height: 30px;
    background-color: #202232;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.leaderboard-record{
    margin: 10px 20px 0px 20px;
    height: 44px; width: calc(100% - 40px);
    width: auto;

    color: inherit;
    border-radius: 40px;
    text-align: left;
    padding: 0 0 0 60px;
    font-size: 20px;
    font-family: inherit;
    
    grid-template-columns: 3% 4.5% 40% 4% 3.5% 15% 15% 15%;
    display: grid;

    border: 0;
    transition: background-color .1s;
    background-color: #2b2e46;
}

.leaderboard-record>span:nth-child(1){display: grid;}
.leaderboard-record>span:nth-child(4){display: grid;}
.leaderboard-record>span:last-child{flex-direction: row-reverse;}
.leaderboard-record>span{
    display: flex;
    place-items: center;
    height: 44px;
}

.leaderboard-record>div>a>span>img{
    height: 36px;
    border-radius: 50px;
    padding: 0;
    scale: .95;
}
.leaderboard-record>div{
    display: grid;
    grid-template-columns: 50% 50%;
    place-items: left;
}
.leaderboard-record>div>a>span{
    display: flex;
    place-items: center;
    height: 44px;
}

.leaderboard-record>span>button{
    background-color: #0000;
    border: 0;
    cursor: pointer;
    transition: opacity 0.1s;
}

.hover-popup {
    position: relative;
    display: inline-block;
  }
  
  .hover-popup::after {
    content: attr(popup-text);
    position: absolute;
    /* top: 0%; */
    /* left: 80%; */
    /* transform: translateX(-100%); */
    /* padding: 5px; */
    background-color: #2b2e46;
    /* border: 1px solid #161723; */
    border-radius: 8px;
    visibility: hidden;
    opacity: 0;
    color: #cdcfdf;
    /* transition: visibility 0s, opacity 0.3s ease; */
  }

  .hover-popup:hover {
    color: transparent;
  }
  
  .hover-popup:hover::after {
    visibility: visible;
    opacity: 1;
  }

.leaderboard-record:last-child{margin: 10px 20px 10px 20px;}


#section7{
    margin: 40px 0 20px 0;
    background-color: #202232;
    border-radius: 24px;
    padding: 10px;
}

#discussion-search{
    height: 46px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 100px;
    margin: 0 0 20px 0;
}
#discussion-search>input::placeholder{color: #aaa;}
#discussion-search>input{
    background-color: #2b2e46;
    font-size: 20px;
    padding-left: 10px;
    color: white;
    border: 0;
    border-radius: 16px 0 0 16px;
    font-family: inherit;
}
#discussion-search>div>button:hover{filter: brightness(75%);}
#discussion-search>div>button{
    padding: 7px 16px;
    margin: 8px 0;
    border: 0;
    font-size: 16px;
    border-radius: 24px;
    display: block;
    background-color:#3c91e6;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    color: white;

    transition: filter .2s;
}
#discussion-search>div{
    background-color: #2b2e46;
    border-radius: 0 16px 16px 0;
}
#discussion-post>button:nth-child(1)>span>b{font-size: 18px;color:#cdcfdf;font-weight: lighter;}
#discussion-post>button:nth-child(1){
    background-color: #2b2e46;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 150px;

    border-radius: 16px;
    padding:  16px 12px;
    margin: 8px 0 0 0;   
    border: 0;
    width: 100%; height: 100px;
    text-align: start;
    white-space: nowrap;
    color: #cdcfdf;
    cursor: pointer;
    overflow: hidden;
}
#discussion-post>button:nth-child(1)>span:nth-child(1){font-size: 32px;}
#discussion-post>button:nth-child(1)>span:nth-child(3){color: #aaa; font-size: 18px;}
#discussion-post>button:nth-child(1)>span:nth-child(4){
    opacity: .7;
    height: 40px;
    display: flex;
    place-items: end;
    justify-content: end;
}

#discussion-post{height: 100px;}
#discussion-post>button>button:hover{filter: brightness(75%); }
#discussion-post>button>button{
    padding: 7px 16px;
    
    border: 0;
    font-size: 16px;
    border-radius: 24px;
    background-color:#e52d04;
    font-family: BarlowSemiCondensed-Regular;
    font-weight: bold;
    cursor: pointer;
    color: white;

    transition: filter .2s;
}


#discussion-create>div{
    display: grid;
    text-align: start;
}    
#discussion-create{    
    display: grid;
    grid-template-columns: 1fr 40px;
    height: auto;
    word-wrap: break-word;
}

#discussion-create>span{padding-left: 20px;}
#discussion-create>div>input::placeholder{color: #aaa;}
#discussion-create>div>input{
    background-color: #2b2e46;
    font-size: 20px;
    padding-left: 10px;
    margin-top: 10px;
    height: 32px;
    color: white;
    border: 0;
    font-family: inherit;
}
#discussion-create>div>input:nth-child(2){font-size: 16px;}

#discussion-create-button:hover{filter: brightness(75%);}
#discussion-create-button{
    padding: 7px 16px;
    margin: 8px 0 0 0;
    border: 0;
    font-size: 16px;
    border-radius: 24px;
    
    background-color:#3c91e6;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    color: white;
    width: min-content;
    grid-column: 1 / span 2;


    transition: filter .2s;
}


#discussion-thread>div:nth-child(1){
    display: grid;
    grid-template-columns: 1fr 40px;
    height: auto;
    padding: 0 0 10px 20px;
    word-wrap: break-word;
}

#discussion-create>button:nth-child(2),
#discussion-thread>div>button{
    height: 40px;
    float:inline-end;
    color:#cdcfdf;
    background-color: #0000;
    border: 0;
    font-size: 38px;
    cursor: pointer;
}


#discussion-thread>div:nth-child(2)>a>img{
    width: 60px; height: 60px;
    border-radius: 100px;
    margin: 20px 0 0 0;
}
#discussion-thread>div:nth-child(2)>div{
    height: max-content;
    padding: 20px 0 0 10px;
    display: inline-grid;
    grid-template-columns: min-content 1fr ;
    overflow: hidden;
    
}
#discussion-thread>div:nth-child(2)>div>span:nth-child(1){font-weight: bold;height: 30px;}
#discussion-thread>div:nth-child(2)>div>span:nth-child(2){
    opacity: 0.6;
    height: 30px;
    font-size: 80%;
    padding-left: 10px;
}
#discussion-thread>div:nth-child(2)>div>span:nth-child(3){
    grid-column: 1 / span 2;
    height: max-content;
    word-wrap: break-word;
}
#discussion-thread>div:nth-child(2){
    display: grid;
    grid-template-columns: 60px 1fr;
    font-size: 20px;
    max-height: 522px;
    overflow-y: auto;
}


#discussion-send{
    height: 48px;
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 80px;
    margin: 10px 0 0 0;
}
#discussion-send>input::placeholder{color: #aaa;}
#discussion-send>input{
    background-color: #2b2e46;
    padding-left: 10px;
    color: white;
    border: 0;
    font-size: 20px;
    border-radius: 16px 0 0 16px;
    font-family: inherit;
}
#discussion-send>div{
    background-color: #2b2e46;
    border-radius: 0 16px 16px 0;

}
#discussion-send>div>button:hover{ filter: brightness(75%);}
#discussion-send>div>button{
    padding: 7px 20px;
    margin: 8px 0;
    font-size: 16px;
    border: 0;
    border-radius: 24px;
    display: block;
    background-color:#3c91e6;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    color: white;

    transition: filter .2s;
}



.triangle{
    display: inline-block;
	width: 8px;	height: 0;
	border-top: 7px solid transparent;
	border-right: 8px solid #cdcfdf;
	border-bottom: 7px solid transparent;
}

 /* such responsive, very mobile */
@media screen and (max-width: 1480px) {
    #section3.summary1{grid-template-columns: auto;}
    #category{min-width: 608px;}
    #history{min-width: 608px;}
    #description{min-width: 608px;}
    #section4.summary1{min-width: 588px;}
    
    #description>iframe{
        padding: 0 0 0 calc(50% - 304px);
        float:none;
        justify-content: center;
        align-items: center;
    }

    #section1.summary1{
        grid-template-columns: auto;
        place-items: center;
        text-align: center;
        
    }

    #section2.summary1{
        grid-template-columns: auto;
        width: 450px;
        margin: 40px auto 0 auto;
    }
    #section2.summary1>.nav-button:nth-child(1){border-radius: 30px 30px 0 0;}
    #section2.summary1>.nav-button:nth-child(2){border-radius: 0;}
    #section2.summary1>.nav-button:nth-child(3){border-radius: 0 0 30px 30px;}
}